<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <!--begin::Card-->
    <div class="card">
      <!--begin::Card header-->
      <div class="card-header border-0 pt-6">
        <!--begin::Card title-->
        <div class="card-title"></div>
        <!--begin::Card title-->
        <!--begin::Card toolbar-->
        <div class="card-toolbar">
          <!--begin::Toolbar-->
          <div
            class="d-flex justify-content-end"
            data-kt-user-table-toolbar="base"
          >
            <!--begin::Add user-->
            <router-link
              v-if="can('smarthealth-admin-list', 'all')"
              to="/apps/smarthealths/smarthealths_admin/create"
            >
              <button type="button" class="btn btn-primary">
                <!--begin::Svg Icon | path: icons/duotone/Navigation/Plus.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <rect
                      fill="#000000"
                      x="4"
                      y="11"
                      width="16"
                      height="2"
                      rx="1"
                    />
                    <rect
                      fill="#000000"
                      opacity="0.5"
                      transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000)"
                      x="4"
                      y="11"
                      width="16"
                      height="2"
                      rx="1"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->เพิ่ม เจ้าหน้าที่ อ.ส.ม
              </button>
            </router-link>
            <!--end::Add user-->
          </div>
          <!--end::Toolbar-->
        </div>
        <!--end::Card toolbar-->
      </div>
      <!--end::Card header-->
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table align-middle table-row-dashed fs-6 gy-5"
            id="kt_table_users"
            style="border-collapse: unset"
          >
            <!--begin::Table head-->
            <thead>
              <!--begin::Table row-->

              <tr
                class="
                  text-start text-gray-400
                  fw-bolder
                  fs-7
                  text-uppercase
                  gs-0
                "
              >
                <th class="min-w-125px">ชื่อ - สกุล</th>
                <th class="min-w-125px">เบอร์โทร</th>

                <th class="text-end min-w-100px">จัดการ</th>
              </tr>
              <!--end::Table row-->
            </thead>
            <!--end::Table head-->
            <!--begin::Table body-->
            <tbody class="text-gray-600 fw-bold" v-if="admins.length > 0">
              <!--begin::Table row-->
              <template v-for="admin in admins" :key="admin.id">
                <tr>
                  <td class="align-items-center">
                    <span v-if="admin.fn_other">{{ admin.fn_other }}</span>
                    <span v-else>{{ admin.fn }}</span> {{ admin.firstname }}
                    {{ admin.lastname }}
                  </td>
                  <td class="align-items-center">
                    {{ admin.tel }}
                  </td>

                  <!--begin::Action=-->
                  <td class="text-end">
                    <a
                      v-if="can('smarthealth-admin-list', 'all')"
                      @click="delateAdmin(admin.id)"
                      class="btn btn-danger btn-active-light-danger btn-sm"
                      >ลบ
                    </a>
                  </td>
                  <!--end::Action=-->
                </tr>
                <!--end::Table row-->
              </template>
            </tbody>
            <!--end::Table body-->
            <tbody class="text-gray-600 fw-bold" v-else>
              <tr>
                <td colspan="3" class="text-center">ยังไม่มีข้อมูล</td>
              </tr>
            </tbody>
          </table>
          <!--end::Table-->
        </div>
        <Pagination v-bind:meta="meta" @pagination="updateHandler"></Pagination>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { defineComponent, onMounted, onUpdated, ref } from "vue";
import {
  MenuComponent,
  ToggleComponent,
  DrawerComponent,
} from "@/assets/js/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkPage } from "@/core/helpers/checkpage";
import useAmins from "@/core/services/api/smarthealth/admin";
import useSweetalert from "@/core/helpers/sweetalert2";
import { useAbility } from "@casl/vue";
export default defineComponent({
  name: "smarthealths-admin",
  components: {
    Pagination,
  },
  setup() {
    const admins = ref([]);
    const meta = ref([]);
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);
    const { getAdmins, updateAdmin } = useAmins();
    const { Sconfirm, SToast } = useSweetalert();
    const { can } = useAbility();
    onMounted(() => {
      getAdmins().then((response) => {
        admins.value = response.data.data;
        meta.value = response.data.meta;
        isLoading.value = false;
      });
      checkPage("smarthealth-admin-list");
      setCurrentPageBreadcrumbs("รายชื่อ", ["ดุแลสุขภาพ", "เจ้าหน้าที่ อ.ส.ม"]);
      DrawerComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
      MenuComponent.reinitialization();
    });

    function delateAdmin(id) {
      Sconfirm("ยืนยันการลบเจ้าหน้าที่", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;
          const data = {
            id: id,
            type: "delete",
          };
          await updateAdmin(data)
            .then(() => {
              SToast("success", "ลบ เจ้าหน้าที่ สำเร็จ");
              getAdmins().then((response) => {
                admins.value = response.data.data;
                meta.value = response.data.meta;
                isLoading.value = false;
              });
            })
            .catch(() => {
              SToast("error", "เกิดข้อผิดพลาด กรุณาลองอีกครั้ง");
            });
        }
      });
    }
    async function updateHandler(e) {
      isLoading.value = true;
      await getAdmins(e);
      isLoading.value = false;
    }
    return { can, admins, delateAdmin, updateHandler, meta, isLoading, color };
  },
});
</script>

<style scoped></style>
