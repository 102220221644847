import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useAmins() {

  const router = useRouter();

  const getAdmins = async (page) => {
    return await ApiService.query("smarthealths_admins",page);
  };

  const getUsers = async (page) => {
    return await ApiService.query("smarthealths_users",page);
  };
  const updateAdmin = async (data) => {
    await ApiService.put("smarthealths_admins/" + data.id, data);
    await router.push({
      name: "apps.smarthealths.smarthealths-admin.index"
    });
  };

  

  return {
    getAdmins,
    getUsers,
    updateAdmin,
  };
}
